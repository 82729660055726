import React, { useEffect, useState } from "react"
import loadable from '@loadable/component'
import { Helmet } from "react-helmet";

import Reviews from "../components/ReviewsSection"
import heroImage from "../assets/images/ContactImages/Contact-Header-Blob.jpg"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import keywords from "../constants/keywords"
import ProcessCards from "../components/OurProcess"
import ContactForm from "../components/ContactPage/ContactForm"
import QuickFacts from "../components/QuickFacts"

const ContactPage = () => {

    return (
        <>
            <Layout black={false}>
                <Helmet>
                <script src="https://unpkg.com/masonry-layout@4.2.2/dist/masonry.pkgd.min.js"></script>
                </Helmet>
                <SEO
                    title={"Contact Us | Differnt Systems"}
                    description={"Get in touch with our team of technology experts to start discussing your business needs. Offices in NJ, NY, PA, NV."}
                    customKeywords={keywords.contact.keywords}
                />
                <div class="offcanvas-wrap">
                    <span class="bg-color"></span>
                    <ContactForm/>
                    <QuickFacts/>
                    {/* <Reviews /> */}
                </div>
            </Layout>

        </>
    )
}

export default ContactPage
