import React, { useEffect, useState } from "react";
import Select from "react-select";

const colourStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.75rem 0.25rem",
    border: "1px solid #ced4da",
    borderRadius: 0,
    backgroundColor: "#eee",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "rgba(71,38,225, 0.70)",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(71,38,225, 0.70)",
  }),
};

const ContactForm = () => {
  const [show, setShow] = useState(false);
  const [selectedTime, setSelectedTime] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track if form is submitting

  const formSubmit = (e) => {
      e.preventDefault();

      // Disable the submit button
      setIsSubmitting(true);

      const formData = {
          "Full Name": e.target.elements["Full Name"].value,
          "Company Name": e.target.elements["Company Name"].value,
          "Phone Number": e.target.elements["Phone Number"].value,
          "Email": e.target.elements["Email"].value,
          "Best Time": selectedTime.map(option => option.label),
          "Message": e.target.elements["Message"].value
      };

      fetch("https://smtp-endpoint.cldsys.com/0ac8c2fb684546587c3c4acf6fdab48e", {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(formData),
      })
      .then((response) => {
          console.log(response);
          setShow(true);
      })
      .catch(error => {
          console.log(error);
          // Re-enable the submit button if submission fails
          setIsSubmitting(false);
      });
  };

  // useEffect to handle form submission
  useEffect(() => {
      const form = document.getElementById("form");
      form.addEventListener("submit", formSubmit);

      // Cleanup function to remove event listener
      return () => form.removeEventListener("submit", formSubmit);
  }, [selectedTime]);  // Ensure useEffect depends on selectedTime

  const timeOptions = [
      { value: 'Urgent (Any Time)', label: 'Urgent (Any Time)' },
      { value: 'Morning (9am - 12pm)', label: 'Morning (9am - 12pm)' },
      { value: 'Afternoon (12pm - 3pm)', label: 'Afternoon (12pm - 3pm)' },
      { value: 'Evening (3pm - 5pm)', label: 'Evening (3pm - 5pm)' }
  ];

  const handleSelectChange = (options) => {
      setSelectedTime(options || []);  // Set to empty array if null
  };

  return (
    <section classID="form" className="py-lg-20 inverted py-xl-20">
      <div style={{ paddingTop: "10%" }} className="pt-5 container">
        <span class="hero-label badge bg-muted-purple text-white rounded-pill mb-3">
          Contact Us
        </span>
        <div className="row justify-content-center">
          <h1 className="mb-1">Become a Client</h1>
          <p className="mb-5">Uncover the Right Solution with Our Differnt Experts.</p>
          <div className="col-lg-12">
            <form id="form" className="row g-3">
              <div className="col-md-6">
                <label htmlFor="yourName" className="form-label text-light">
                  Full Name *
                </label>
                <input
                  name="Full Name"
                  type="text"
                  className="bg-light form-control"
                  id="yourName"
                  placeholder="James Lafferty"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="yourCompany" className="form-label text-light">
                  Company Name
                </label>
                <input
                  name="Company Name"
                  type="text"
                  className="bg-light form-control"
                  id="company"
                  placeholder="ABC Company LLC"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="yourNumber" className="form-label text-light">
                  Phone Number *
                </label>
                <input
                  name="Phone Number"
                  type="text"
                  className="bg-light form-control"
                  id="number"
                  placeholder="609-123-4567 Ext. 100"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="yourEmail" className="form-label text-light">
                  Business Email *
                </label>
                <input
                  name="Email"
                  type="email"
                  className="bg-light form-control"
                  id="email"
                  placeholder="email@company.com"
                  required
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="time" className="form-label text-light">
                  Best Contact Time(s) *
                </label>
                <Select
                  name="Best Time"
                  options={timeOptions}
                  isMulti
                  onChange={handleSelectChange}
                  value={selectedTime}
                  isSearchable={false}
                  styles={colourStyles}
                  placeholder={"Select all that apply..."}
                  required
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="yourMessage" className="form-label text-light">
                  Introduce Your Your Problem/Idea *
                </label>
                <textarea
                  name="Message"
                  className="bg-light form-control"
                  id="yourMessage"
                  rows={3}
                  placeholder="I am encountering an issue / seeking to develop..."
                  defaultValue={""}
                  required
                />
              </div>
              <div className="col-md-12 mt-5">
                <button
                  type="submit"
                  className="btn btn-block btn-purple rounded-pill"
                  disabled={isSubmitting} // Disable the button while submitting
                >
                  {isSubmitting ? "Thank You" : "Submit Request"}
                </button>
              </div>
              {show && (
                <div className="col-12 mt-5">
                  <div className="alert alert-primary" role="alert">
                    Request Sent! Our Team Will Follow Up Shortly.
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
