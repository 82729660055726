import { left } from 'glamor';
import React, { useEffect } from 'react';
import Google from "../assets/images/logo/Google-Reviews.png"
import Clutch from "../assets/images/logo/Clutch-Reviews.png"
import reviews from "../constants/reviews.js"
import heroImage from "../assets/images/ContactImages/Contact-Header-Blob.jpg"

import * as PIXI from "pixi.js";
import { KawaseBlurFilter } from "@pixi/filter-kawase-blur";
import SimplexNoise from "simplex-noise";
import hsl from "hsl-to-hex";
import debounce from "debounce";

function Reviews() {

    let random = Math.floor(Math.random() * reviews.length)
    // alert(random)
    let reviewAuthor = reviews[random].author
    let reviewQuote = reviews[random].quote

    return (
        // <div>
        // </div>
        <section className="py-10 pb-10 inverted" style={{background:"rgba(48,13,129,.08)"}}>
            {/* to make this wider, remove the container class name */}
            <div className="container pt-5">
                <canvas className="orb-canvas" />
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="row justify-content-center mb-5">
                            <div className="text-left">
                                <h3 className="fs-4"></h3>
                                <h2>Ready To <span className="fw-bold text-green">Earn</span> Your Business.</h2>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul className="list-unstyled border-bottom">
                                    <li className="border-top py-5">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div className="col-md-3 mb-4 mb-md-0">
                                                <div className="d-flex align-items-end mb-1">
                                                    <span className="fs-4 fw-bold lh-1 mb-0 me-1">5.0</span>
                                                    <span className="text-muted">out of 5</span>
                                                </div>
                                                <ul className="rating text-yellow">
                                                    <li>
                                                        <i className="bi bi-star-fill" />
                                                    </li>
                                                    <li>
                                                        <i className="bi bi-star-fill" />
                                                    </li>
                                                    <li>
                                                        <i className="bi bi-star-fill" />
                                                    </li>
                                                    <li>
                                                        <i className="bi bi-star-fill" />
                                                    </li>
                                                    <li>
                                                        <i className="bi bi-star-fill" />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-9">
                                                <p className="mb-2 lead text-secondary">
                                                    {reviewQuote}
                                                </p>
                                                <span className="eyebrow">
                                                    {reviewAuthor}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="mt-3 row pb-5 justify-content-start">
                                    <div className='col-6 col-md-4 col-lg-3'>
                                        <a href="https://g.page/f-s-digital-nj?share" target="_blank">
                                            <img
                                                style={{ maxHeight: 30, width: "auto" }}
                                                src={Google}
                                                alt="Logo"
                                                className="logo lozad"
                                            />
                                        </a>
                                    </div>

                                    <div className='col-6 col-md-4 col-lg-3' style={{ marginLeft: '-13%' }}>
                                        <a href="https://clutch.co/profile/fs-digital#summary" target="_blank">
                                            <img
                                                style={{ maxHeight: 30, width: "auto" }}
                                                src={Clutch}
                                                alt="Logo"
                                                className="logo lozad"
                                            />
                                        </a>
                                    </div>

                                </div>
                                {/* <a href="/contact#form">
                                    <p>
                                        <u>Speak With Our 5-Star Team</u>{" "}
                                        <i className="mt-7 bi bi-arrow-down-right" />
                                    </p>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <span
                style={{opacity: .5, zIndex: '-100', backgroundImage:`url(${heroImage})`}}
                className="background"
                data-top-top="transform: translateY(0%);"
                data-top-bottom="transform: translateY(10%);"
            ></span> */}
        </section>
    )
}


export default Reviews;