import React from 'react';
import UserIcon from "../assets/images/svg/icons/User-Collaborate.svg"
import MultiplePeople from "../assets/images/svg/icons/Multiple-People.svg"
import Time from "../assets/images/svg/icons/Share-Time.svg"
import { Helmet } from "react-helmet";
import 'masonry-layout'

function QuickFacts(props) {    
    return (
<section className="inverted mb-5 py-10 py-xl-10">
<div className="container">
    <div className="row align-items-center justify-content-between">
        <div className="col-md-10 col-xl-5 mb-5 mb-xl-0">
            <h2>Personal. <span className="fw-bold text-green">Efficient</span>. Respected.</h2>
            <p className="fs-7 text-white text-secondary">
            We champion flexible work environments, delivering cost-effective solutions without compromising quality. Our nationwide team is ready to engage in person, hybrid, or fully remote—adapting to your project needs and preferences. This versatility ensures personalized service and optimal collaboration for every client.
            </p>
            {/* <a href="" class="action underline">Give Us A Call <i class="bi bi-arrow-right"></i></a> */}
            <br />
            <a href="tel:8002625108">
                <p className="mb-2">
                    <b>Main Phone</b><br />
                    (800)-262-5108 <i className="bi bi-arrow-up-right" />
                </p>
            </a>
            <a href="mailto:hello@differnt.com">
                <p className="mb-2">
                    <b>General Email</b><br />
                    hello@differnt.com
                    <i className="bi bi-arrow-up-right" />
                </p>
            </a>
            <a href="https://g.page/f-s-digital-nj?share" target="_blank">
                <p className="mb-2">
                    <b>Corporate Headquarters</b><br />
                    5215 Wellington Ave STE 600B, Ventnor City, NJ <i className="bi bi-arrow-up-right" />
                </p>
            </a>
            <a>
                <p>
                    <b>New York Regional Office</b><br />
                    Opening Soon
                </p>
                <p>
                    <b>Nevada Regional Office</b><br />
                    Opening Soon
                </p>
                <p>
                    <b>Pennsylvania Regional Office</b><br />
                    Opening Soon
                </p>
            </a>
        </div>
        <div className="col-xl-6">
            <div className="row g-3 g-xl-5" data-masonry>
                <div className="col-md-6" data-aos="fade-up">
                    <div className="card equal-xl-1-1 bg-purple inverted">
                        <div className="card-wrap">
                            <div className="card-header pb-0">
                                <img src={Time} style={{ width: '40px' }}></img>
                            </div>
                            <div className="card-footer mt-auto">
                                <h3 className="fs-4">365 Support and Response</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-xl-20" data-aos="fade-up">
                    <div className="card equal-xl-1-1 bg-green inverted">
                        <div className="card-wrap">
                            <div className="card-header pb-0">
                                <img src={UserIcon} style={{ width: '40px' }}></img>
                            </div>
                            <div className="card-footer mt-auto">
                                <h3 className="fs-4">On-Location or Remote Ready</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up">
                    <div className="card equal-xl-1-1" style={{ backgroundColor: '#270336' }}>
                        <div className="card-wrap">
                            <div className="card-header pb-0">
                                <img src={MultiplePeople} style={{ width: '40px' }}></img>
                            </div>
                            <div className="card-footer mt-auto">
                                <h3 className="fs-4">50+ Team Members</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

)}

export default QuickFacts;